@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
@import "modules/responsive-type.scss";

$primary: #006cb5;
/* MAIN COLOR */
$secondary: #55c4cf;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html,
body {

    height: 100%;
}

// body {
//     overflow-x: hidden;
// }

html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: 'Quicksand',
        sans-serif;

}

body {
    font-family: 'Quicksand',
        sans-serif;

}

p {
    font-size: 1rem;
    font-family: 'Quicksand',
        sans-serif;
    font-weight: 400;
}


h1 {
    margin: 1rem 0;
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    margin: 1rem 0;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“""”";
}

.navbar-default {
    background: white;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 18px;
            display: flex;
            align-items: center;
            color: $blk;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }

            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1280px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 8px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;

    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
            font-size: 25px;
        }
    }

    a.btn,
    a.btn-default,
    button {
        @include btn margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $secondary;
    color: $footerLinks;
    font-size: 15px;

    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-height: 100px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-height: 70px;
    }

    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }

    & ol {
        li {
            font-size: 1rem;
        }

        & ol li {
            font-size: 0.85rem;
        }
    }
}

.banner {
    background: linear-gradient(rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)), url(../img/banner.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }

    background-position: 50% 0%;
    padding: 14rem 0;

    @media (max-width: 991px) {
        padding: 8rem 0;
    }

    h1,
    h2 {
        color: white;
        background: rgba($blk, .2);
        line-height: 3em ;
    }

    h1 {
        font-weight: 700;
        text-transform: uppercase;
        

    }

    h2 {
        font-weight: 400;
        padding: 0 1rem;
        @include fluid-type($min_width, $max_width, 20px, 40px);
    }
}

.iconContain {
    .iconBox {
        span[class*="ti"] {
            font-size: 2rem;
            color: white;
        }

        background: $primary;
        display: inline-block;
        padding: 1rem;
        border-radius: 50%;
    }

    h1 {
        font-size: 1rem;
        color: $primary;
    }
}

.margTopBtm {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.midSection {
    padding: 0;
    margin: 0;

    h1 {
        color: $wht;
    }

    p {
        @include fluid-type($min_width, $max_width, 17px, 30px);
        color: $wht;
    }

    .bg {
        background: url(../img/analysis.jpg) no-repeat;
        background-size: cover;
        padding: 12rem 0;

        @media (max-width: 991px) {
            padding: 9rem 0;
            background-position: 50% 60%;
        }
    }

    .tble {
        display: table;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        padding: 0;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .tble-cell {
        display: table-cell;
        vertical-align: middle;
        float: none;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .txtBox {
        padding-left: 3rem;
        padding-right: 3rem;
        margin: 0;
        background: $primary;

        @media (max-width: 767px) {
            padding: 3rem 15px;
        }
    }
}

.services {
    h1 {
        color: $primary;
        // font-size: 1.5rem;
        text-transform: uppercase;border-bottom: 1px solid #000;
    }
    h2 {
        font-size: 1.2rem;
        color: $primary;
    }
    p {
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    [class*="col-sm-4"].imgBox {
        img {
            @media (max-width: 767px) {
                max-width: 300px;
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

.solidBg {
    background: $secondary;
    padding: 3rem 15px;

    h2 {
        color: $wht;
        @include fluid-type($min_width, $max_width, 17px, 30px);
    }
}

.contact {
    background: $primary;
    color: $wht;

    a {
        color: $wht;

        &:focus,
        &:hover {
            color: $wht;
            text-decoration: none;
        }
    }

    h1,
    h2 {
        @include fluid-type($min_width, $max_width, 17px, 34px);
    }
}

.padTopBtm {
    padding: 3rem 15px;
}

br.visible-600 {
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
}

.hidden-small {
    @media (max-width: 600px) {
        display: none;
    }
}

.show-600 {
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: transparent;
}